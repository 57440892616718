<template>
    <div class="battery-container">
        <div v-for="(item, index) in setData(content)" :key="item.index" class="battery">
            <!-- <div class="battery-title">{{ item.value }}{{ battery_type[index].unit }}</div> -->
            <div class="battery-subtitle">{{ battery_type[index].value }}</div>
            <progress-bar 
                :options="batteryOptions"
                :value="percentageToValue(item.value)"
            ></progress-bar>
        </div>
    </div>
</template>

<script>
import ProgressBar from 'vuejs-progress-bar'
export default {
    name: 'BatteryBar',
    components: {
        ProgressBar
    },
    props: {
        content: {
            type: Array
        },
        device: {
            type: Object
        }
    },
    data() {
        return {
            battery_type: [
                {
                    'code': 1,
                    'id': 1,
                    'value': '배터리1',
                    'unit': '(V)',
                },
                {
                    'code': 1,
                    'id': 2,
                    'value': '배터리2',
                    'unit': '(V)',
                },
            ],
            batteryOptions: {
                text: {
                    hideText: false,
                    fontSize: '22',
                    dynamicPosition: true
                    
                },
                progress: {
                    color: '#3f79ff',
                    backgroundColor: '#e6e9f0'
                    
                },
                layout: {
                    height: 90,
                    width: 160,
                    type: 'battery'
                }
            }
        }
    },
    methods: {
        setData(content) {
            if(content) {
                let data = content

                data = data.filter(item => item.type === 1)

                return data
            }
        },
        percentageToValue(batteryValue) {
            let percentage = 0
            if(batteryValue > 4) return percentage = 100;
            else if(batteryValue > 3.7 && batteryValue <= 4) return percentage = 80;
            else if(batteryValue > 3.6 && batteryValue <= 3.7) return percentage = 50;
            else if(batteryValue > 3.5 && batteryValue <= 3.6) return percentage = 30;
            else if(batteryValue > 3.4 && batteryValue <= 3.5) return percentage = 10;
            else if(batteryValue <= 3.4) return percentage = 5;
            else return percentage = 0
        }
    }
}
</script>

<style scoped>
.battery-container {
    width: 180px;
    height: 180px;
}

.battery {
    position: relative;
    margin-top: 3px;
}

.battery-title{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 30%;
    text-align: center;
    transform: translate(-60%, -100%);
    font-size: 24px;

}

.battery-subtitle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 30%;
    text-align: center;
    /* font-weight: bold; */
    font-size: 15px;
    transform: translate(-55%, -135%);
}
</style>